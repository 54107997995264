@font-face {
    font-family: "Poppins";
    src: url("../../assets/font/Poppins.ttf") format("truetype");
}

*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', serif;
    background-color: #FCFCFC;
    overflow-y: hidden;
}

.react-pdf__Page__textContent.textLayer, .react-pdf__Page__annotations.annotationLayer {
    display: none !important;
}

// add the code bellow
@layer utilities {
    @variants responsive {

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}