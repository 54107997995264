@import "../root/_root_datePicker.scss";
@import "../mixin/mixin_datePicker.scss";

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.react-datepicker {
    font-family: $datepicker__font-family;
    font-size: $datepicker__font-size;
    background-color: #fff;
    color: $datepicker__text-color;
    border: 1px solid $datepicker__border-color;
    border-radius: $datepicker__border-radius;
    display: inline-block;
    position: relative;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    padding: $datepicker__padding;
    @apply tm:w-[375px] w-screen
}

.react-datepicker--time-only {
    .react-datepicker__triangle {
        left: 35px;
    }

    .react-datepicker__time-container {
        border-left: 0;
    }

    .react-datepicker__time,
    .react-datepicker__time-box {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
    display: none;
}

.react-datepicker-popper {
    z-index: 30;

    &[data-placement^="bottom"] {
        padding-top: $datepicker__triangle-size + 2px;

        .react-datepicker__triangle {
            @extend .triangle-arrow-up;
        }
    }

    &[data-placement="bottom-end"],
    &[data-placement="top-end"] {
        .react-datepicker__triangle {
            left: auto;
            right: 50px;
        }
    }

    &[data-placement^="top"] {
        padding-bottom: $datepicker__triangle-size + 2px;

        .react-datepicker__triangle {
            @extend .triangle-arrow-down;
        }
    }

    &[data-placement="right"] {
        padding-left: $datepicker__triangle-size;

        .react-datepicker__triangle {
            left: auto;
            right: 42px;
        }
    }

    &[data-placement="left"] {
        padding-right: $datepicker__triangle-size;

        .react-datepicker__triangle {
            left: 42px;
            right: auto;
        }
    }
}

.react-datepicker__header {
    text-align: center;
    position: relative;

    &--time {
        padding-bottom: 8px;
        padding-left: 5px;
        padding-right: 5px;

        &:not(&--only) {
            border-top-left-radius: 0;
        }
    }

    &:not(&--has-time-select) {
        border-top-right-radius: $datepicker__border-radius;
    }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    display: flex;
    margin-top: 0;
    color: $datepicker__header-color;
    font-weight: bold;
    font-size: $datepicker__font-size * 1.18;
    text-transform: capitalize;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    position: absolute;
    cursor: pointer;
    right: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: $datepicker__navigation-button-size;
    width: $datepicker__navigation-button-size;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
        right: 58px;
    }

    &--next {
        right: 2px;

        &--with-time:not(&--with-today-button) {
            right: 85px;
        }
    }

    &--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &-previous {
            top: 4px;
        }

        &-upcoming {
            top: -4px;
        }
    }

    &:hover {
        *::before {
            border-color: darken($datepicker__muted-color, 15%);
        }
    }
}

.react-datepicker__navigation-icon {
    top: -1px;
    font-size: 20px;
    width: 0;
    color: $datepicker__muted-color;

    &::before {
        @extend .navigation-chevron;
    }

    &--next {
        left: -2px;

        &::before {
            transform: rotate(45deg);
            left: -7px;
        }
    }

    &--previous {
        right: -2px;

        &::before {
            transform: rotate(225deg);
            right: -7px;
        }
    }
}

.react-datepicker__month-container {
    float: left;
    width: 100%;
}

.react-datepicker__year {
    margin: $datepicker__margin;
    text-align: center;

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 180px;
    }

    .react-datepicker__year-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;

    .react-datepicker-time__caption {
        display: inline-block;
    }

    .react-datepicker-time__input-container {
        display: inline-block;

        .react-datepicker-time__input {
            display: inline-block;
            margin-left: 10px;

            input {
                width: auto;
            }

            input[type="time"]::-webkit-inner-spin-button,
            input[type="time"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="time"] {
                -moz-appearance: textfield;
            }
        }

        .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }
    }
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid $datepicker__border-color;
    width: 85px;

    &--with-today-button {
        display: inline;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        position: absolute;
        right: -72px;
        top: 0;
    }

    .react-datepicker__time {
        position: relative;
        background: white;
        border-bottom-right-radius: 0.3rem;

        .react-datepicker__time-box {
            width: 85px;
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;
            border-bottom-right-radius: 0.3rem;

            ul.react-datepicker__time-list {
                list-style: none;
                margin: 0;
                height: calc(195px + (#{$datepicker__item-size} / 2));
                overflow-y: scroll;
                padding-right: 0;
                padding-left: 0;
                width: 100%;
                box-sizing: content-box;

                li.react-datepicker__time-list-item {
                    height: 30px;
                    padding: 5px 10px;
                    white-space: nowrap;

                    &:hover {
                        cursor: pointer;
                        background-color: $datepicker__background-color;
                    }

                    &--selected {
                        background-color: $datepicker__selected-color;
                        color: white;
                        font-weight: bold;

                        &:hover {
                            background-color: $datepicker__selected-color;
                        }
                    }

                    &--disabled {
                        color: $datepicker__muted-color;

                        &:hover {
                            cursor: default;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

.react-datepicker__week-number {
    color: $datepicker__muted-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;

    &.react-datepicker__week-number--clickable {
        cursor: pointer;

        &:hover {
            border-radius: $datepicker__border-radius;
            @apply bg-yellow-400
        }
    }
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-evenly;
}

.react-datepicker__day-names {
    margin: 7px 0;
}

.react-datepicker__month-select {
    text-transform: capitalize;
}

.react-datepicker__header__dropdown {
    margin: 7px 0;
    text-transform: capitalize;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $datepicker__text-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;
    text-transform: capitalize;
}

.react-datepicker__day--disabled {
    color: $datepicker__disabled-color;
    cursor: default;
}

.react-datepicker__day-name,
.react-datepicker__day {
    width: 51px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.react-datepicker__month,
.react-datepicker__quarter {
    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__selected-color;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__selected-color, 5%);
        }
    }

    &--disabled {
        color: $datepicker__muted-color;
        pointer-events: none;

        &:hover {
            cursor: default;
            background-color: transparent;
        }
    }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;

    &:hover {
        border-radius: $datepicker__border-radius;
        @apply bg-yellow-400
    }

    &--today {
        font-weight: bold;
    }

    &--highlighted {
        border-radius: $datepicker__border-radius;
        @apply bg-yellow-400;
        color: #fff;

        &:hover {
            @apply bg-yellow-400;
        }

        &-custom-1 {
            color: magenta;
        }

        &-custom-2 {
            color: green;
        }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: 10px;
        @apply bg-yellow-500;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__selected-color, 5%);
        }
    }

    &--keyboard-selected {
        border-radius: $datepicker__border-radius;
        @apply bg-yellow-500;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__selected-color, 5%);
        }
    }

    &--in-selecting-range:not(&--in-range) {
        @apply bg-yellow-300
    }

    &--in-range:not(&--in-selecting-range) {
        .react-datepicker__month--selecting-range & {
            background-color: $datepicker__background-color;
            color: $datepicker__text-color;
        }
    }

    &--disabled {
        cursor: default;
        color: $datepicker__muted-color;

        &:hover {
            background-color: transparent;
        }
    }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
    &.react-datepicker__month--selected,
    &.react-datepicker__month--in-range,
    &.react-datepicker__quarter--selected,
    &.react-datepicker__quarter--in-range {
        &:hover {
            background-color: $datepicker__selected-color;
        }
    }

    &:hover {
        background-color: $datepicker__background-color;
    }
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: $datepicker__border-radius;
    position: relative;

    &:hover {
        cursor: pointer;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow {
            border-top-color: darken($datepicker__muted-color, 10%);
        }
    }

    &--down-arrow {
        @extend .navigation-chevron;

        transform: rotate(135deg);
        right: -16px;
        top: 0;
    }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: $datepicker__background-color;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: $datepicker__border-radius;
    border: 1px solid $datepicker__border-color;

    &:hover {
        cursor: pointer;
    }

    &--scrollable {
        height: 150px;
        overflow-y: scroll;
    }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
        border-top-left-radius: $datepicker__border-radius;
        border-top-right-radius: $datepicker__border-radius;
    }

    &:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: $datepicker__border-radius;
        border-bottom-right-radius: $datepicker__border-radius;
    }

    &:hover {
        background-color: $datepicker__muted-color;

        .react-datepicker__navigation--years-upcoming {
            border-bottom-color: darken($datepicker__muted-color, 10%);
        }

        .react-datepicker__navigation--years-previous {
            border-top-color: darken($datepicker__muted-color, 10%);
        }
    }

    &--selected {
        position: absolute;
        left: 15px;
    }
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;

    &::after {
        cursor: pointer;
        background-color: $datepicker__selected-color;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 2px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "\00d7";
    }
}

.react-datepicker__today-button {
    @apply bg-yellow-500;
    border-top: 1px solid $datepicker__border-color;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    @media (max-width: 400px), (max-height: 550px) {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            width: 2rem;
            line-height: 2rem;
        }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        font-size: $datepicker__font-size * 1.8;
    }
}
