@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins.ttf") format("truetype");
};

$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #111827 !default;
$datepicker__disabled-color: #a7a7a7 !default;
$datepicker__selected-color: #9ca3af !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: 10px !default;
$datepicker__padding: 28px 24px !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 14px !default;
$datepicker__font-family: "Poppins", helvetica, arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;